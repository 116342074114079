<template>
  <div class="height">
      <div class="device-swipe">
          <van-swipe class="my-swipe" :autoplay="code.images_video.swipe_interval*1000" indicator-color="white" v-if="code && code.images_video.swipe_type==1">
            <van-swipe-item v-for="(images,index) in code.images_video.images" v-bind:key="index">
              <img :src="images.url"/>
            </van-swipe-item>
          </van-swipe>
          <video class="dev-swipe" loop muted autoplay controls v-if="code && code.images_video.swipe_type==2">
            <source
              :src="code.images_video.video"
              type="video/mp4"
            />
          </video>
      </div>
      <div class="device-bottom">
        <div class="device-main" @click="useFun">开始使用</div>
        <div class="flex flex-x">
            <div class="flex">
                <div class="device-num" v-if="code">设备编号：<span>{{code.info.equipment_code}}</span></div>
                <div class="device-num device-history" v-if="code && code.info.testing_type!==4" @click="historyLoginFun">历史记录</div>
            </div>
        </div>
      </div>
      
      <div v-if="show">
        <vue-popup url="confirmAssess" :showFun="showFun" audio="welcome" />
      </div>
      
      <div class="yt-jpg flex flex-y flex-x" v-if="show1">
          <div class="yt-ugk" v-if="insura_state==1">
              <div class="yt-box ">
                  <div class="yt-jb">
                      <div class="insura-title">请选择登录方式</div>
                      <div class="yt-cross" @click="cross">
                        <van-icon name="cross" />
                      </div>
                  </div>
                  <div class="yt-top flex flex-y flex-x">
                    <div  class="yt-box-sao flex flex-x">
                        <div class="sao-jo" @click="idFun">
                            <div class="sao-img flex flex-x">
                                <div>
                                  <div class="sao-img-box">
                                    <img src="../../../images/sfz2.png"/>
                                  </div>
                                </div>
                            </div>
                            <div class="sao-title-box">身份证登录</div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
          <div v-if="insura_state==5" class="insura-box-bc">
              <div class="insura-title">身份证登录</div>
              <div class="insura-box flex flex-x">
                <div class="insura-img insura-jom flex flex-x">
                    <img src="../../../images/sfz.png"/>
                </div>
              </div>
              <div class="insura-na">正在读卡中...</div>
              <div class="insura-state flex">
                <div>读卡器状态：</div>
                <div v-if="insura_card==1" class="store"></div>
                  <div v-if="insura_card==2" class="red"></div>
                <div v-if="insura_card==3" class="yellow"></div>
              </div>
              <div class="exit" @click="exitFun">退出</div>
          </div>
          <div v-if="insura_state==4" class="insura-box-bc">
              <div class="insura-iox flex">
                <div class="flex-1"></div>
                <div class="insura-return" @click="exitFun">✕</div>
              </div>
              <div class="insura-box">
                <div class="insura-na flex">
                  <div>姓名：</div>
                  <div class="insura-right flex-1">
                      {{info?info.userName:''}}
                  </div>
                </div>
                <div class="insura-na flex">
                  <div>身份证号：</div>
                  <div class="insura-right flex-1">
                      {{info?hidenNum(info.idCode,3,4):''}}
                  </div>
                </div>
                <div class="insura-na flex">
                  <div>出生年月：</div>
                  <div class="insura-right flex-1">{{info?info.birthday:''}}</div>
                </div>
              </div>
              <div class="que-d" @click="questionFun">确定</div>
          </div>
      </div>
      <div v-if="history">
          <vue-popup :showFun="noLoginFun" url="reportList" :query="{return:true}" :login="true" text="搜索报告" />
      </div>
  </div>
</template>

<script>
import { mapActions , mapState , mapMutations } from 'vuex';
import vuePopup from "@/components/popup";
import { audioPaly } from "@/utils";
import { welcome  } from "@/utils/audio";

export default {
  name: 'device',
  data(){
    return{
      show: false,
      show1:false,
      insura_state:1,
      insura_card:1
    }
  },
  components:{
    vuePopup
  },
  created:function(){
      this.deviceFun(this.axiosFun);
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('info');
      if(sessionStorage.device=='android'){
          //安卓使用H5摄像头
          if(this.$route.query.edtion && this.$route.query.edtion<2){
              sessionStorage.setItem("camera", "true");
          }
      }
  },
  destroyed: function () {
      this.changeFun({
        key:'history',
        value:false
      })
  },
  computed:{
    ...mapState('device',['code','info']),
    ...mapState ("report", ['history']),
  },
  methods:{
      //获取数据
      axiosFun(mac){
        this.axiosData({
          data:{mac:mac},
          _this:this
        });
      },
      cross(){
        this.show1=false
      },
      idFun(){
        this.insura_state=5;
        this.insura_card=1;
        this.identityData({data:{},fun:(data)=>{
          if(this.insura_state==5){
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            this.axiosTel({
              data:{
                  company_id:equipment?equipment.sys_company_id:'',
                  phone:'',
                  name:data.userName,
                  birthday:data.birthday
              },
              func:()=>{
                this.insura_state=4;
              }
            })
          }
        }});
      },
      exitFun(){
        this.insura_state=1;
        this.insura_card=1;
      },
      questionFun(){
        //语音
        audioPaly.play(welcome);
        this.$router.push({
            name:'confirmAssess'
        });
      },
      //身份证脱敏
      hidenNum(str, frontLen, endLen){
        if(str && str.length > 10){
          var len = str.length - frontLen - endLen;
          var xing = '';
          for (var i=0; i<len; i++) {
            xing += '*';
          }
          return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
        }
        return str;
      },
      //判断设备环境
      deviceFun(axiosFun){
        let device = sessionStorage.getItem('device');
        if(device=='windows'){
          let mac = localStorage.mac;
          if(mac){
              axiosFun(mac);
          }else{
              window.ipcRenderer.send("getMacAddress");
              window.ipcRenderer.on("setMacAddress", (e, msg) => {
                axiosFun(msg);
                localStorage.setItem("mac",msg);
              });
          }
        }else if(device=='android'||this.$route.query.mac){
          axiosFun(this.$route.query.mac);
        }
      },
      useFun(){
        let device = this.$store.state.device;
        if(device.code){
          if(device.code.info.testing_type==2){ //扫码
            this.show = true;
          }else if(device.code.info.testing_type==4||device.code.info.testing_type==6){   //刷卡
            this.show1 = true;
          }else if(device.code.info.testing_type==5){   //本地数据
            let _this = this;
            window.ipcRenderer.send("getFileInfo");
            window.ipcRenderer.on("setFileInfo", (e, data) => {
              if(data.code){
                  data.data = JSON.parse(data.data);
                  console.log(data.data);
                  let info = {
                    userName:data.data.name,
                    gender:data.data.sex,
                    birthday:data.data.birthday,
                    idCode:data.data.id_card,
                    height:data.data.height?data.data.height:'',
                    weight:data.data.weight?data.data.weight:'',
                    phone:data.data.phone?data.data.phone:'',
                    random:data.data.random
                  }
                  sessionStorage.setItem("info",JSON.stringify(info));
                  let equipment = JSON.parse(sessionStorage.getItem('equipment'));
                  _this.axiosTel({
                    data:{
                        company_id:equipment?equipment.sys_company_id:'',
                        phone:'',
                        name:data.data.name,
                        birthday:data.data.birthday
                    },
                    func:()=>{
                      //语音
                      audioPaly.play(welcome);
                      _this.$router.push({
                          name:'confirmAssess'
                      });
                    }
                  })
              }else{
                sessionStorage.removeItem('info');
                audioPaly.play(welcome);
                _this.$router.push({
                    name:'confirmAssess'
                });
              }
            });
          }else{
            //语音
            audioPaly.play(welcome);
            this.$router.push({
                name:'confirmAssess'
            });
          }
        }
      },
      //退出登录
      noLoginFun(){
          this.changeFun({
              key:'history',
              value:false
          })
      },
      historyLoginFun(){
          if(sessionStorage.token){
            this.$router.push({
                name:'reportList'
            });
          }else{
            //登录
            this.changeFun({
                key:'history',
                value:true
            })
          }
      },
      showFun(){
        this.show = false;
      },
      ...mapMutations ("report", ["changeFun"]),
      ...mapActions('device',['axiosData','identityData','axiosTel']),
  }
}
</script>

<style lang="scss" scoped> 
    @import './device'; 
</style>